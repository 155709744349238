import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FileModel } from '@app/core/models/file/file.model';
import { EnumController } from '@app/core/services/end-point-extend';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';
import { BaseService } from '../base.service';

@Injectable({
    providedIn: 'root'
})
export class FileService extends BaseService {
    private file_api_endpoint = `${environment.API_ENDPOINT}${this.version}/${EnumController.file}`;

    constructor(private http: HttpClient, private appService: AppService) {
        super();
    }

    public postFile(file: File): Observable<FileModel> {
        const headers = new HttpHeaders().append('Content-Disposition', "name='file");
        const contractId = this.appService.getUserContext().contract.id.toString();
        const formData = new FormData();
        formData.append('file', file, file.name);
        return this.http.post<FileModel>(
            `${this.file_api_endpoint}?contractId=${contractId}`,
            formData,
            {
                headers: headers
            }
        );
    }

    public getFile(fileId: number): Observable<Blob> {
        const contractId = this.appService.getUserContext().contract.id.toString();
        return this.http.get(`${this.file_api_endpoint}/${fileId}?contractId=${contractId}`, {
            responseType: 'blob'
        });
    }
}
