import { KeyValue } from '@angular/common';

export enum Civility {
    monsieur = 'Mr',
    madame = 'Mme'
}

export enum OrganizationSectorName {
    'Tertiaire' = 1,
    'Résidentiel collectif' = 2,
    'Collectivités' = 3,
    'Tous secteurs' = 4
}

export const CIVILITY_LIST = ['Madame', 'Monsieur'];

export enum enumGeographicLevelId {
    nation = -3,
    region = -2,
    county = -1,
    town = 0,
    establishment = 1,
    building = 2,
    zone = 3,
    district = 11,
    street = 12,
    cabinet = 13
}

export const enumGeographicLevelRSX: Array<KeyValue<enumGeographicLevelId, string>> = [
    { key: enumGeographicLevelId.nation, value: 'geoMenu.levels.nations' },
    { key: enumGeographicLevelId.region, value: 'geoMenu.levels.regions' },
    { key: enumGeographicLevelId.county, value: 'geoMenu.levels.county' },
    { key: enumGeographicLevelId.town, value: 'geoMenu.levels.towns' },
    { key: enumGeographicLevelId.establishment, value: 'geoMenu.levels.establishments' },
    { key: enumGeographicLevelId.building, value: 'geoMenu.levels.buildings' },
    { key: enumGeographicLevelId.zone, value: 'geoMenu.levels.zones' },
    { key: enumGeographicLevelId.district, value: 'geoMenu.levels.districts' },
    { key: enumGeographicLevelId.street, value: 'geoMenu.levels.streets' },
    { key: enumGeographicLevelId.cabinet, value: 'geoMenu.levels.cabinets' }
];

export enum ThermicUsage {
    ECS = 'ECS',
    Chauffage = 'Chauffage',
    Refroidissement = 'Refroidissement',
    Eclairage = 'Eclairage'
}

export enum FileImportStatus {
    All = null,
    InProgress = 1,
    OK = 2,
    Error = 3
}

export enum TertiaryDecreeTypeId {
    SIRET = 1,
    RNA = 2,
    Other = 3
}

export const TertiaryDecreeTypeIdList: {
    id: TertiaryDecreeTypeId;
    label: string;
    pattern: RegExp;
    maxLength: number;
    errorMsg: string;
}[] = [
    {
        id: TertiaryDecreeTypeId.SIRET,
        label: 'SIRET',
        pattern: RegExp('\\d{14}'),
        maxLength: 14,
        errorMsg: 'Numéro SIRET invalide'
    },
    {
        id: TertiaryDecreeTypeId.RNA,
        label: 'RNA',
        pattern: RegExp('(W)(\\d{9})'),
        maxLength: 10,
        errorMsg: 'Numéro RNA invalide'
    },
    {
        id: TertiaryDecreeTypeId.Other,
        label: 'Autre',
        pattern: null,
        maxLength: 100,
        errorMsg: 'Numéro invalide'
    }
];

export enum OccupationTypeId {
    OccupyingOwner = 1,
    NonOccupyingOwner = 2,
    Tenant = 3
}

export const OccupationTypeList: Array<KeyValue<OccupationTypeId, string>> = [
    { key: OccupationTypeId.OccupyingOwner, value: 'Propriétaire occupant' },
    { key: OccupationTypeId.NonOccupyingOwner, value: 'Propriétaire non occupant' },
    { key: OccupationTypeId.Tenant, value: 'Locataire' }
];

export const MAX_LENGTH_ID = 100;

export const ALL_ITEMS_DEFAULT_VALUE = -1;

export enum ConfigurationChoice {
    Existing = 1,
    New = 2
}

export enum GridSort {
    ASC = 'asc',
    DESC = 'desc'
}

export class GridSortState {
    dir?: GridSort;
    fieldName?: string;
}

export enum OperatExportStatus {
    InProgress,
    OK,
    Failed
}
