import { ScrollingModule } from '@angular/cdk-experimental/scrolling';
import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { HttpClientJsonpModule } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { WidgetBaseComponent } from '@features/common/widget-base/widget-base.component';

import {
    NgxMatDatetimePickerModule,
    NgxMatNativeDateModule,
    NgxMatTimepickerModule
} from '@angular-material-components/datetime-picker';

// import { AgmCoreModule } from '@agm/core';
// import { CoreModule } from '@core/core.module';
import { TranslateModule } from '@ngx-translate/core';

import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DatePickerModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { DropDownListModule } from '@progress/kendo-angular-dropdowns';
import { GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { SwitchModule } from '@progress/kendo-angular-inputs';
import { IntlModule } from '@progress/kendo-angular-intl';
import { LabelModule } from '@progress/kendo-angular-label';
import { PanelBarModule, TabStripModule } from '@progress/kendo-angular-layout';
import { PopupModule } from '@progress/kendo-angular-popup';
import { ToolBarModule } from '@progress/kendo-angular-toolbar';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { BootstrapModule } from '@shared/bootstrap/bootstrap.module';
import { FormArrayFilterPipe } from '@shared/pipes/form-array-filter.pipe';
import { VertuozLibraryModule } from '@vertuoz/vertuoz-library';
import { ClickOutsideModule } from 'ng-click-outside';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ColorPickerModule } from 'ngx-color-picker';

import { ConfirmDialogModule } from '@app/features/common/confirm-dialog/confirm-dialog.module';
import { IconographicMessageComponent } from '@app/features/common/iconographic-message/iconographic-message.component';
import { WidgetBaseModule } from '@app/features/common/widget-base/widget-base.module';
import { FilterComponent } from '@app/shared/components/filter/filter.component';

import { AssociateToSupplierContractComponent } from '@features/energy-supplier-contracts/common/associate-to-supplier-contract.component';

import { GroupManagementComponent } from '@features/perimeter/common/group-management/group-management.component';
import { GeographicMenuComponent } from '@shared/components/geographic-menu/geographic-menu.component';
import { SelectTextFieldComponent } from '@shared/components/select-text-field/select-text-field.component';
import { AccountSettingsComponent } from './account-settings/account-settings.component';
import { EmailSettingsComponent } from './account-settings/settings-update/email-settings/email-settings.component';
import { OptinSettingsComponent } from './account-settings/settings-update/optin-settings/optin-settings.component';
import { PersonnalInformationsComponent } from './account-settings/settings-update/personnal-informations/personnal-informations.component';
import { AnchorDialogComponent } from './anchor-dialog/anchor-dialog.component';
import { BulkActionSnackbarComponent } from './bulk-action-snackbar/bulk-action-snackbar.component';
import { CustomDialogMenuComponent } from './bulk-action-snackbar/custom-dialog-menu/custom-dialog-menu.component';
import { CustomDialogComponent } from './bulk-action-snackbar/custom-dialog/custom-dialog.component';
import { GroupSelectorComponent } from './bulk-action-snackbar/group-selector/group-selector.component';
import { ToggleActionComponent } from './bulk-action-snackbar/toggle-action/toggle-action.component';
import { BaseComponent } from './components/base.component';
import { ChartGraphicOptionsComponent } from './components/chart-graphic-options/chart-graphic-options.component';
import { OdometerComponent } from './components/chart-graphic-options/odometer/odometer/odometer.component';
import { ConfirmDialogComponent } from './components/confirm-dialog/confirm-dialog.component';
import { CustomSelectOptionComponent } from './components/custom-dropdown/custom-select-option.component';
import { CustomSelectComponent } from './components/custom-dropdown/custom-select.component';
import { DropdownComponent } from './components/custom-dropdown/dropdown.component';
import { DelayedButtonComponent } from './components/delayed-button/delayed-button.component';
import { ExportDataComponent } from './components/export-data/export-data.component';
import { ExportTypeComponent } from './components/export-data/export-type/export-type.component';
import { FluidDisplayComponent } from './components/fluid-display/fluid-display.component';
import { FluidIconComponent } from './components/fluid-icon/fluid-icon.component';
import { GroupsTabComponent } from './components/geographic-menu/groups-tab/groups-tab.component';
import { DynamicFormFilterComponent } from './components/grid-template/dynamic-form-filter/dynamic-form-filter.component';
import { GroupsSelectorComponent } from './components/groups-selector/groups-selector.component';

import { LoaderComponent } from './components/loader/loader.component';
import { LogoDisplayComponent } from './components/logo-display/logo-display.component';

import { EquipmentTypeIconComponent } from './components/equipment-type-icon/equipment-type-icon.component';
import { MatTabPerimeterSelectorComponent } from './components/mat-tab-perimeter-selector/mat-tab-perimeter-selector.component';
import { MeteringPointDisplayComponent } from './components/meteringpoint-display/meteringpoint-display.component';
import { ModalDialogComponent } from './components/modal/modal-dialog/modal-dialog.component';
import {
    VertuozDeleteComponent,
    VertuozDeleteModalComponent
} from './components/modal/vertuoz-delete-modal/vertuoz-delete-modal.component';
import {
    VertuozUpdateComponent,
    VertuozUpdateModalComponent
} from './components/modal/vertuoz-update-modal/vertuoz-update-modal.component';
import { PerimeterSelectorLevelComponent } from './components/perimeter-selector/perimeter-selector-level/perimeter-selector-level.component';
import { PerimeterSelectorComponent } from './components/perimeter-selector/perimeter-selector.component';
import { PerimeterWidgetDetailsComponent } from './components/perimeter-widget-detail/perimeter-widget-details.component';
import { ProgressBarComponent } from './components/progressbar/progressbar.component';
import { ProgressBarService } from './components/progressbar/progressbar.service';
import { RenewableEnergyIconComponent } from './components/renewable-energy-icon/renewable-energy-icon.component';
import { SensorMeasureIconComponent } from './components/sensor-measure-icon/sensor-measure-icon.component';
import { VStepperComponent } from './components/stepper/stepper.component';
import { VertuozAutocompleteSelectComponent } from './components/vertuoz-autocomplete-select/vertuoz-autocomplete-select.component';
import { VertuozChartFiltersComponent } from './components/vertuoz-chart-filters/vertuoz-chart-filters.component';
import { VertuozChartSettingsComponent } from './components/vertuoz-chart-settings/vertuoz-chart-settings.component';
import { VertuozChartComponent } from './components/vertuoz-chart/vertuoz-chart.component';
import { VertuozInfiniteScrollMatSelectComponent } from './components/vertuoz-infinite-scroll-mat-select/vertuoz-infinite-scroll-mat-select.component';
import { VertuozRangeSelectorComponent } from './components/vertuoz-range-selector/vertuoz-range-selector.component';
import { DecimalInputDirective } from './directive/decimal-input.directive';
import { FileDownloadDirective } from './directive/file-download.directive';
import { HasDomainsDirective } from './directive/has-domains.directive';
import { HasPermissionsDirective } from './directive/has-permissions.directive';
import { RouterExternalLinkDirective } from './directive/router-external-link.directive';
import { MaterialModule } from './material/material.module';
import { GroupByPipe } from './pipes/group-by.pipe';
import { HoursFormatPipe, HoursMinutesFormatPipe } from './pipes/hours-minutes-format.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { PadNumberPipe } from './pipes/pad-number-with-leading-zeros.pipe';

import { AgmCoreModule } from '@agm/core';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { MatMenuModule } from '@angular/material/menu';
import { UploadModule } from '@progress/kendo-angular-upload';
import { MentionsLegalesComponent } from './components/footer/mentions-legales/mentions-legales.component';
import { ReleaseNotesComponent } from './components/footer/release-notes/release-notes.component';
import { VertuozFooterComponent } from './components/footer/vertuoz-footer.component';
import { MaxRangeDirective } from './directive/max-range-selection.directive';
import { OptionsScrollDirective } from './directive/options-scroll.directive';
import {
    VertuozAssignmentDirective,
    VertuozAssignmentModalComponent
} from './directive/vertuoz-assignment/vertuoz-assignment.directive';

import { ThresholdGraphComponent } from './components/threshold-graph/threshold-graph.component';
import { DisabledControlDirective } from './directive/disable-control.directive';
import { LinkedFileComponent } from './components/linked-file/linked-file/linked-file.component';
import { LinkedFileDisplayComponent } from './components/linked-file/linked-file-display/linked-file-display.component';
import { LinkedFileDownloadComponent } from './components/linked-file/linked-file-download/linked-file-download.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { StoreModule } from '@ngrx/store';
import { geographicMenuModuleReducer } from './components/geographic-menu/store/geographic-menu.reducer';
import { geoMenuModuleStoreKey } from './components/geographic-menu/store/geographic-menu.selector';
import { GeoMenuService } from './components/geographic-menu/geo-menu.service';

@NgModule({
    imports: [
        AgmCoreModule,
        RouterModule,
        ScrollingModule,
        InfiniteScrollModule,
        CommonModule,
        MaterialModule,
        BootstrapModule,
        ReactiveFormsModule,
        FormsModule,
        LayoutModule,
        GridModule,
        PanelBarModule,
        TabStripModule,
        PopupModule,
        ToolBarModule,
        DropDownListModule,
        InputsModule,
        ButtonsModule,
        DialogsModule,
        GridModule,
        HttpClientJsonpModule,
        PopupModule,
        ReactiveFormsModule,
        FormsModule,
        ButtonsModule,
        InputsModule,
        IntlModule,
        ColorPickerModule,
        DateInputsModule,
        LabelModule,
        DropDownsModule,
        ToolBarModule,
        DialogsModule,
        DatePickerModule,
        GridModule,
        SwitchModule,
        TooltipModule,
        ConfirmDialogModule,
        ClickOutsideModule,
        TranslateModule,
        WidgetBaseModule,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        NgxMatTimepickerModule,
        MatSelectInfiniteScrollModule,
        VertuozLibraryModule,
        UploadModule,
        MatMenuModule,
        DragDropModule,
        MatTooltipModule,
        StoreModule.forFeature(geoMenuModuleStoreKey, {
            geographicMenuModuleState: geographicMenuModuleReducer
        })
    ],
    declarations: [
        ConfirmDialogComponent,
        VertuozUpdateModalComponent,
        ModalDialogComponent,
        BaseComponent,
        FileDownloadDirective,
        DisabledControlDirective,
        RouterExternalLinkDirective,
        DecimalInputDirective,
        HasDomainsDirective,
        HasPermissionsDirective,
        OptionsScrollDirective,
        MaxRangeDirective,
        AccountSettingsComponent,
        BulkActionSnackbarComponent,
        LoaderComponent,
        ProgressBarComponent,
        AnchorDialogComponent,
        ToggleActionComponent,
        DelayedButtonComponent,
        ConfirmDialogComponent,
        CustomDialogComponent,
        OptinSettingsComponent,
        PersonnalInformationsComponent,
        GroupSelectorComponent,
        EmailSettingsComponent,
        OrderByPipe,
        FormArrayFilterPipe,
        GroupByPipe,
        PadNumberPipe,
        HoursMinutesFormatPipe,
        HoursFormatPipe,
        LogoDisplayComponent,
        GroupsSelectorComponent,
        PerimeterSelectorComponent,
        PerimeterSelectorLevelComponent,
        VertuozChartComponent,
        VertuozChartSettingsComponent,
        ExportDataComponent,
        ExportTypeComponent,
        CustomSelectComponent,
        CustomSelectOptionComponent,
        DropdownComponent,
        GeographicMenuComponent,
        MatTabPerimeterSelectorComponent,
        GroupsTabComponent,
        DynamicFormFilterComponent,
        FilterComponent,
        VertuozInfiniteScrollMatSelectComponent,
        VertuozAutocompleteSelectComponent,
        ChartGraphicOptionsComponent,
        IconographicMessageComponent,
        GroupManagementComponent,
        OdometerComponent,
        VertuozChartFiltersComponent,
        VertuozDeleteModalComponent,
        VertuozDeleteComponent,
        VertuozUpdateModalComponent,
        VertuozUpdateComponent,
        VertuozRangeSelectorComponent,
        ModalDialogComponent,
        FluidDisplayComponent,
        FluidIconComponent,
        CustomDialogMenuComponent,
        MeteringPointDisplayComponent,
        RenewableEnergyIconComponent,
        PerimeterWidgetDetailsComponent,
        WidgetBaseComponent,
        EquipmentTypeIconComponent,
        VertuozFooterComponent,
        MentionsLegalesComponent,
        ReleaseNotesComponent,
        AssociateToSupplierContractComponent,
        SelectTextFieldComponent,
        VertuozAssignmentDirective,
        VertuozAssignmentModalComponent,
        VStepperComponent,
        SensorMeasureIconComponent,
        ThresholdGraphComponent,
        LinkedFileComponent,
        LinkedFileDisplayComponent,
        LinkedFileDownloadComponent
    ],
    exports: [
        AgmCoreModule,
        MaterialModule,
        BootstrapModule,
        MatMenuModule,
        RouterModule,
        ReactiveFormsModule,
        FormsModule,
        LayoutModule,
        GridModule,
        TabStripModule,
        TranslateModule,
        PopupModule,
        ToolBarModule,
        DropDownListModule,
        InputsModule,
        ButtonsModule,
        DialogsModule,
        GridModule,
        HttpClientJsonpModule,
        PopupModule,
        ReactiveFormsModule,
        FormsModule,
        VertuozLibraryModule,
        CommonModule,
        UploadModule,
        ConfirmDialogModule,
        ButtonsModule,
        InputsModule,
        IntlModule,
        DateInputsModule,
        LabelModule,
        DropDownsModule,
        ToolBarModule,
        DialogsModule,
        DatePickerModule,
        ColorPickerModule,
        GridModule,
        SwitchModule,
        TooltipModule,
        MatTooltipModule,
        ConfirmDialogComponent,
        VertuozDeleteModalComponent,
        VertuozUpdateModalComponent,
        VertuozAssignmentModalComponent,
        ModalDialogComponent,
        BaseComponent,
        FileDownloadDirective,
        DisabledControlDirective,
        RouterExternalLinkDirective,
        DecimalInputDirective,
        HasDomainsDirective,
        HasPermissionsDirective,
        VertuozAssignmentDirective,
        MaxRangeDirective,
        AccountSettingsComponent,
        BulkActionSnackbarComponent,
        LoaderComponent,
        ProgressBarComponent,
        AnchorDialogComponent,
        ToggleActionComponent,
        DelayedButtonComponent,
        ConfirmDialogComponent,
        CustomDialogComponent,
        OptinSettingsComponent,
        PersonnalInformationsComponent,
        GroupSelectorComponent,
        EmailSettingsComponent,
        OrderByPipe,
        FormArrayFilterPipe,
        GroupByPipe,
        PadNumberPipe,
        HoursMinutesFormatPipe,
        HoursFormatPipe,
        LogoDisplayComponent,
        GroupsSelectorComponent,
        PerimeterSelectorComponent,
        PerimeterSelectorLevelComponent,
        VertuozChartComponent,
        VertuozChartSettingsComponent,
        ExportDataComponent,
        ExportTypeComponent,
        CustomSelectComponent,
        CustomSelectOptionComponent,
        DropdownComponent,
        GeographicMenuComponent,
        MatTabPerimeterSelectorComponent,
        GroupsTabComponent,
        DynamicFormFilterComponent,
        FilterComponent,
        VertuozInfiniteScrollMatSelectComponent,
        VertuozAutocompleteSelectComponent,
        ChartGraphicOptionsComponent,
        IconographicMessageComponent,
        GroupManagementComponent,
        OdometerComponent,
        VertuozChartFiltersComponent,
        VertuozDeleteComponent,
        VertuozUpdateModalComponent,
        VertuozUpdateComponent,
        VertuozRangeSelectorComponent,
        ModalDialogComponent,
        FluidDisplayComponent,
        FluidIconComponent,
        CustomDialogMenuComponent,
        MeteringPointDisplayComponent,
        RenewableEnergyIconComponent,
        PerimeterWidgetDetailsComponent,
        WidgetBaseComponent,
        NgxMatDatetimePickerModule,
        NgxMatNativeDateModule,
        NgxMatTimepickerModule,
        MatSelectInfiniteScrollModule,
        EquipmentTypeIconComponent,
        VertuozFooterComponent,
        AssociateToSupplierContractComponent,
        SelectTextFieldComponent,
        VStepperComponent,
        DragDropModule,
        ThresholdGraphComponent,
        LinkedFileComponent,
        LinkedFileDisplayComponent,
        LinkedFileDownloadComponent
    ],

    providers: [ProgressBarService, GeoMenuService],
    schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule {}
