export enum appRoutes {
    default = '',
    cgu = 'cgu',
    map = 'map',
    init = 'init',
    login = 'login',
    notFound = '404',
    groups = 'groups',
    search = 'search',
    objectives = 'objectives',
    fluids = 'fluids-view',
    property = 'property',
    invoices = 'invoices',
    readings = 'readings',
    initHelp = 'init/help',
    meters = 'meters-view',
    dashboard = 'dashboard',
    scenarios = 'scenarios',
    depotFile = 'depot-file',
    fileImport = 'scenarios/file-import',
    fileImportInvoices = 'invoices/file-import',
    fileImportReadings = 'readings/file-import',
    initImport = 'init/help',
    unauthorized = 'unauthorized',
    meteringPoint = 'metering-point',
    administration = 'administration',
    propertyData = 'property-data',
    propertyDataEnergy = 'property-data/energy',
    propertyDataWater = 'property-data/water',
    externalRedirect = 'externalRedirect',
    automaticInvoice = 'automatic-invoice',
    importsHistorics = 'imports-historics',
    addAutomaticInvoiceConfig = 'add-automatic-invoice-config',
    customerSectorsAdministration = 'administration/custom-sectors',
    rolesList = '/administration/roles',
    device = 'device',
    measure = 'measures',
    geography = 'geography',
    publicSpace = 'public-space',
    loadCurve = 'load-curve',
    restitution = 'restitution-objectives',
    aggregateProduction = 'aggregate-production',
    equipments = 'equipments',
    energySupplierContracts = 'energy-supplier-contracts',
    dataControl = 'data-control',
    vehicle = 'vehicles',
    vehicleInvoice = 'vehicle-invoices',
    siteDisabled = 'site-disabled'
}

export enum ReadingRoute {
    path = 'readings',
    display = 'display',
    add = 'add',
    edit = 'edit'
}
