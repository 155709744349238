import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BatchUpdateParams } from '@app/core/models/common/batch-update-params.model';
import { PageResult } from '@app/core/models/common/page-result.model';
import { DataControlNotificationFilter } from '@app/core/models/data-control/filters/data-control.filter';
import { DataControlNotificationModel } from '@app/core/models/data-control/models/data-control-notification.model';
import { EnumController } from '@app/core/services/end-point-extend';
import { PagedContext } from '@app/features/shared/models/paged-context';
import { ToolsService } from '@app/features/shared/services/tools.service';
import { FileExportType } from '@app/shared/constants/export.enum';
import { PerimeterScope } from '@app/shared/constants/filters.enum';
import { environment } from '@environments/environment';
import { Observable } from 'rxjs';
import { AppService } from '../app.service';
import { BaseService } from '../base.service';

@Injectable({
    providedIn: 'root'
})
export class DataControlNotificationsService extends BaseService {
    //#region fields
    private API_DATACONTROL_RULES = `${environment.API_ENDPOINT}${this.version}/${EnumController.datacontrolnotifications}`;
    //#endregion

    //#region Constructor
    constructor(
        private http: HttpClient,
        private appService: AppService,
        private toolsService: ToolsService
    ) {
        super();
    }
    //#endregion

    //#region Methods

    /// <summary>
    /// Add rules mail subscription for current user
    /// </summary>
    public addSubscriptionsRule(rulesIds: number[]): Observable<number> {
        const contractId = this.appService.getUserContext().contract.id.toString();

        return this.http.post<number>(
            `${this.API_DATACONTROL_RULES}?contractId=${contractId}`,
            rulesIds
        );
    }

    /// <summary>
    /// Get data control notifications
    /// </summary>
    public getNotifications(
        filter: DataControlNotificationFilter,
        pageContext: PagedContext,
        perimeterScope: PerimeterScope
    ): Observable<PageResult<DataControlNotificationModel>> {
        const contractId = this.appService.getUserContext().contract.id.toString();
        const queryStr = this.toolsService.transformParamsArrayToQueryString(
            { withUTCTime: true },
            filter,
            pageContext,
            { perimeterScope: perimeterScope },
            { contractId: contractId }
        );

        return this.http.get<PageResult<DataControlNotificationModel>>(
            `${this.API_DATACONTROL_RULES}?${queryStr}`
        );
    }

    /// <summary>
    /// Get data control notification
    /// </summary>
    public getNotification(id: number): Observable<DataControlNotificationModel> {
        const contractId = this.appService.getUserContext().contract.id.toString();

        return this.http.get<DataControlNotificationModel>(
            `${this.API_DATACONTROL_RULES}/${id}?contractId=${contractId}`
        );
    }

    /// <summary>
    /// Delete data control notifications
    /// </summary>
    public deleteNotifications(
        ids: number[],
        filter: DataControlNotificationFilter
    ): Observable<void> {
        const contractId = this.appService.getUserContext().contract.id.toString();
        let queryStr = ';';
        if (filter) {
            queryStr = this.toolsService.transformParamsToQueryString(filter);
        } else {
            queryStr = this.toolsService.transformParamsToQueryString({ ids });
        }

        return this.http.delete<void>(
            `${this.API_DATACONTROL_RULES}?${queryStr}&contractId=${contractId}`
        );
    }

    /// <summary>
    /// Batch update single property on notifications list
    /// </summary>
    public batchUpdateNotifications(batchUpdateParams: BatchUpdateParams): Observable<void> {
        const contractId = this.appService.getUserContext().contract.id.toString();

        return this.http.post<void>(
            `${this.API_DATACONTROL_RULES}/BatchUpdate?contractId=${contractId}`,
            batchUpdateParams
        );
    }

    public GetFileDatas(
        fileType: FileExportType,
        notificationsFilter: DataControlNotificationFilter,
        perimeterScope: PerimeterScope
    ): Observable<HttpResponse<Object>> {
        const contractId = this.appService.getUserContext().contract.id.toString();
        const queryStr = this.toolsService.transformParamsArrayToQueryString(
            { contractId: contractId },
            notificationsFilter,
            { perimeterScope: perimeterScope },
            { fileType: fileType }
        );
        return this.http.get(`${this.API_DATACONTROL_RULES}/FileDatas?${queryStr}`, {
            responseType: 'blob' as 'json',
            observe: 'response'
        });
    }

    //#endregion
}
